<template>
  <div>
    <!-- <video id="background-video" autoplay loop muted poster="">
      <source :src="require('@/assets/matrix-code.mp4')" type="video/mp4">
    </video> -->
    <nav-bar v-if="this.$store.state.isAuthenticated" />
    <side-menu />
    <modal-movie
      v-if="modalVisible"
      :isEdit="isEdit"
      :movieToEdit="JSON.parse(JSON.stringify(movieToEdit))"
      @save-movie="(value) => savedMovie = value"
    />
    <!-- overlay loading screen -->
    <div v-if="isLoading" class="is-loading">
        <div class="spinner"></div>
    </div>
    <div class="movie container">
      <div class="movie-wrapper">
        <div
          class="card"
          v-for="movie in filtered.movies"
          :key="movie.id"
          :model="movie"
        >
          <div class="card-content m-0 p-0">
            <div class="media">
              <div class="media-left">
                <figure class="image m-0">
                  <img
                    class="movie-cover"
                    :src="movie.cover || movie.cover_url || require('@/assets/default_cover.jpg')"
                    alt="Cover"
                  >
                </figure>
              </div>
              <div class="media-content pt-4">
                <p class="title is-5 mb-1">{{ movie.name }}
                  <font-awesome-icon
                    v-if="getColorCode(movie.currently_watching_jate, movie.currently_watching_flo)"
                    class="font-awe-icon bookmark-icon"
                    :class="getColorCode(movie.currently_watching_jate, movie.currently_watching_flo)"
                    :icon="['fas', 'bookmark']"
                  />
                </p>
                <div class="movie-content">
                  <!-- movie stats -->
                  <div>
                    <span v-if="movie.release_year">
                      {{ movie.release_year }}
                      <font-awesome-icon
                        class="font-awe-icon hash-icon"
                        :icon="['fas', 'diamond']"
                      />
                    </span>
                    <span v-if="movie.country">
                      {{ movie.country.name }}
                      <font-awesome-icon
                        class="font-awe-icon hash-icon"
                        :icon="['fas', 'diamond']"
                      />
                    </span>
                    <span v-if="movie.running_time">
                      <span class="nobr">{{ movie.running_time }} Min.</span>
                      <font-awesome-icon
                        class="font-awe-icon hash-icon"
                        :icon="['fas', 'diamond']"
                      />
                    </span>
                    <span v-if="movie.director">
                        <span class="nobr">{{ movie.director.name }}</span>
                        <font-awesome-icon
                          class="font-awe-icon hash-icon"
                          :icon="['fas', 'diamond']"
                        />
                    </span>
                    <span v-if="movie.imdb">
                      <span
                        class="card imdb"
                      >
                        {{ movie.imdb.toFixed(1) }}
                      </span>
                    </span>
                  </div>
                  <!-- genres -->
                  <div
                    v-if="movie.genres"
                  >
                    <span
                      v-for="genre in movie.genres"
                      :key="genre.id"
                      :model="genre"
                      class="card genre"
                      :style="{
                          color: genre.theme.font_color,
                          'background-color': genre.theme.background_color,
                        }"
                    >
                      <span class="nobr mt-2">{{ genre.name }}</span>
                    </span>
                  </div>
                  <!-- tags -->
                  <div v-if="movie.tags.length">
                    <span
                      v-for="tag in movie.tags"
                      :key="tag.id"
                      :model="tag"
                      class="card tag-object"
                    >
                      <span class="mt-2 nobr">
                        {{ tag.name }}
                      </span>
                    </span>
                  </div>
                  <!-- icons -->
                  <div class="icon-wrapper">
                    <span
                      v-for="emoji in emojis"
                      :key="emoji.id"
                      :model="emoji"
                    >
                      <font-awesome-icon v-if="getColorCode(movie.rating_by_jate, movie.rating_by_flo, emoji.icon_code)"
                        class="font-awe-icon emoji-icon"
                        :class="getColorCode(movie.rating_by_jate, movie.rating_by_flo, emoji.icon_code)"
                        :icon="['far', emoji.icon_code]"
                      />
                    </span>
                    <font-awesome-icon
                      v-if="getColorCode(movie.seen_by_jate, movie.seen_by_flo)"
                      class="font-awe-icon emoji-icon"
                      :class="getColorCode(movie.seen_by_jate, movie.seen_by_flo)"
                      :icon="['far', 'eye']"
                    />
                    <font-awesome-icon
                      v-if="getColorCode(!movie.seen_by_jate, !movie.seen_by_flo)"
                      class="font-awe-icon emoji-icon"
                      :class="getColorCode(!movie.seen_by_jate, !movie.seen_by_flo)"
                      :icon="['far', 'eye-slash']"
                    />
                    <font-awesome-icon
                      class="font-awe-icon edit-icon"
                      :icon="['far', 'pen-to-square']"
                      v-on:click="editMovie(movie)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination
        v-if="!isLoading"
        :pagination="filtered.pagination"
        @go-to="(value) => goTo(value)"
      />
    </div>

  </div>
</template>

<script>
import axios from "@/main.js";
import NavBar from "@/components/NavBar";
import SideMenu from "@/components/SideMenu";
import Pagination from "@/components/Pagination";
import ModalMovie from "@/components/ModalMovie";
import Confirmation from "@/components/Confirmation";

export default {
  name: "Movies",
  data() {
    return {
      isEdit: false,
      movieToEdit: {},
      movies: [],
      emojis: [],
      currentPage: { value: 1, hasChanged: false },
      entriesPerPage: 10,
      modalVisible: false,
      savedMovie: false,
      isLoading: false
    };
  },
  mounted() {
    this.getMovies();
    this.getEmojis();
    this.getGenres();
    this.getTags();
    this.getCountries();
    this.getDirectors();
  },
  computed: {
    search() {
      return this.$store.state.search;
    },
    isCurrentlyWatched() {
      return this.$store.state.isCurrentlyWatched;
    },
    isUnseen() {
      return this.$store.state.isUnseen;
    },
    isMovie() {
      return this.$store.state.isMovie;
    },
    isSeries() {
      return this.$store.state.isSeries;
    },
    imdb() {
      return this.$store.state.imdb;
    },
    duration() {
      return this.$store.state.duration;
    },
    year() {
      return this.$store.state.year;
    },
    // return an array of paginated movies matching the filter criteria
    // and an array of the pagination data
    filtered() {
      const selectedGenres = []
      this.$store.state.genres.forEach(genre => {
        if (genre.selected) {
          selectedGenres.push(genre.name)
        }
      })
      const filteredMovies = [];
      const search = this.search.toString().toLowerCase();
        for (const movie of this.movies) {
          if (
            (
              // the text search
              this.search === "" ||
              movie.name.toLowerCase().includes(search) ||
              (movie.director && movie.director.name.toLowerCase().includes(search)) ||
              this.arrayHasString(movie.tags, search)
            ) && (
              // the checkbox for currently watching
              !this.isCurrentlyWatched ||
              this.isCurrentlyWatched && (movie.currently_watching_jate || movie.currently_watching_flo)
            ) && (
              // the checkbox for not seen yet
              !this.isUnseen ||
              this.isUnseen && (!movie.seen_by_jate || !movie.seen_by_flo)
            ) && (
              // the checkboxes for movie only and series only
              (!this.isMovie && !this.isSeries) ||
              (this.isMovie && !movie.is_series) ||
              (this.isSeries && movie.is_series)
            ) && (
              // at least rating according to imdb input
              (this.imdb.isInvalid || this.imdb.value === '') ||
              movie.imdb >= this.imdb.value
            ) && (
              // at most duration according to duration input
              (this.duration.isInvalid || this.duration.value === '') ||
              movie.running_time <= this.duration.value
            ) && (
              // release year between the two inputs
              (this.year.start <= movie.release_year || this.year.start === '' || this.year.isInvalidStart) &&
              (this.year.end >= movie.release_year || this.year.end === '' || this.year.isInvalidend)
            ) &&
            // all selected genres
            selectedGenres.every(selectedGenre => movie.genres.some(genre => genre.name === selectedGenre))
          ) {
            filteredMovies.push(movie);
          }
        }
        // pagination
        const currentPage = this.currentPage.hasChanged ? this.currentPage.value : 1
        this.currentPage = { value: currentPage, hasChanged: false }
        const siteCount = parseInt(Math.ceil(filteredMovies.length / this.entriesPerPage))
        const hasNext = currentPage < siteCount ? true : false
        const hasPrev = currentPage > 1 ? true : false
        const movies = filteredMovies.slice((currentPage - 1) * this.entriesPerPage, currentPage * this.entriesPerPage)

        return { movies, pagination: { siteCount, hasNext, hasPrev, currentPage } }
    },
  },
  methods: {
    getMovies() {
      this.isLoading = true
      axios.get("/movies/").then((response) => {
        this.movies = response.data;
        if (this.savedMovie) {
          const index = this.movies.findIndex(movie => movie.id === this.savedMovie)
          this.goTo(this.getPage(this.movies[index]))
          this.savedMovie = false
        }
        this.isLoading = false
      });
    },
    getEmojis() {
      axios.get("/emojis/").then((response) => {
        this.emojis = response.data;
        this.$store.commit("setEmojis", response.data);
      });
    },
    getGenres() {
      axios.get("/genres/").then((response) => {
        this.$store.commit("setGenres", response.data);
      });
    },
    getTags() {
      axios.get("/tags/").then((response) => {
        this.$store.commit("setTags", response.data);
      });
    },
    getDirectors() {
      axios.get("/directors/").then((response) => {
        this.$store.commit("setDirectors", response.data);
      });
    },
    getCountries() {
      axios.get("/countries/").then((response) => {
        this.$store.commit("setCountries", response.data);
      });
    },
    arrayHasString(values, search) {
      for (const value of values) {
        if (value.name.toLowerCase().includes(search)) {
          return true;
        }
      }
      return false;
    },
    getColorCode(jate, flo, code='nothing') {
      if (code !== 'nothing' && jate !== null) {
        jate = jate.icon_code
      }
      if (code !== 'nothing' && flo !== null) {
        flo = flo.icon_code
      }
      if ((jate && flo && code === 'nothing') || (jate === code && flo === code)) {
        return 'both'
      } else if (jate && code === 'nothing' || jate === code) {
        return 'jate'
      } else if (flo && code === 'nothing' || flo === code) {
        return 'flo'
      } else {
        false
      }
    },
    toggleModal() {
      this.modalVisible ? (this.modalVisible = false, this.isEdit = false) : this.modalVisible = true
    },
    goTo(page) {
      this.currentPage = { value: page, hasChanged: true }
      window.scrollTo(0,0)
    },
    editMovie(movie) {
      this.isEdit = true;
      this.movieToEdit = movie
      this.toggleModal()
    },
    getPage(movie) {
      if (this.filtered.movies.indexOf(movie) < 0) {
        return Math.ceil((this.movies.indexOf(movie) +1) / this.entriesPerPage)
      } else {
        return Math.ceil((this.filtered.movies.indexOf(movie) +1) / this.entriesPerPage)
      }
    }
  },
  components: {
    NavBar,
    SideMenu,
    Pagination,
    ModalMovie,
    Confirmation
  }
};
</script>
<style scoped lang="scss">

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.movie {
  margin: 5rem 0.5rem 0.5rem 0.5rem;
}

.movie-content span {
  font-size: 0.8rem;
}

.title {
  line-height: unset;
}

.icon-wrapper {
  margin-top: 0.3rem;
}

.font-awe-icon {
  font-size: 0.4rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.hash-icon {
  margin-bottom: 0.15rem;
}

.emoji-icon {
  font-size: 1rem;
}

.edit-icon {
  font-size: 1.2rem;
  position: absolute;
  bottom: 0.8rem;
  right: 0.6rem;
  cursor: pointer;
}

.bookmark-icon {
  font-size: 1.8rem;
  position: absolute;
  top: -0.2rem;
  right: 0.6rem;
}

@media only screen and (max-width: 1023px) {
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
}

@media only screen and (min-width: 1024px) {
  .movie {
    margin-left: 25%;
    margin-right: 25%;
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
  }
}

figure {
  width: 8rem;
  height: 12rem;
}

.movie-cover {
  border-radius: 0.25rem 0 0 0.25rem;
  object-fit: cover;
  width: 128px;
  height: 192px;
}

.card {
  background-color: silver;
  font-weight: bold;
  min-width: 355px;
}

.genre {
  margin-right: 0.4rem;
  padding: 3px;
  font-size: 9pt;
}

.tag-object {
  margin-right: 0.4rem;
  margin-top: 0.5rem;
  padding: 1px;
  border: 2px solid;
  font-size: 9pt;
  background-color: white;
}

.movie-wrapper {
  display: inline-grid;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  width: 100%;
}

.jate {
  color: #66ff99;
}

.flo {
  color: green;
}

@media only screen and (min-width: 1800px) {
  .movie-wrapper {
    display: inline-grid;
    grid-template-rows: auto;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .movie-content span {
    font-size: 0.9rem;
  }

  .title {
    font-size: 1.5rem;
  }
}

// @media screen and (min-width: 1408px) {
//   .container:not(.is-max-desktop):not(.is-max-widescreen) {
//     max-width: 100%;
//   }
// }

.nobr {
  display: inline-block;
  width: max-content;
}

.imdb {
  padding: 3px;
  background-color: #f5c518;
  color: #000000;
  font-weight: 800;
}

.pointer-events-none {
    pointer-events: none;
}

.is-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    // opacity: 0.5;
    z-index: 5;
    // background: rgba(255, 255, 255, 0.9);
}

.spinner {
   position: absolute;
   left: calc(50% - 30px);
   top: calc(50% - 94px);
   height:60px;
   width:60px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}
</style>