import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Movies from '../views/Movies.vue'
import Recipes from '../views/Recipes.vue'
import Games from '../views/Games.vue'
import Activities from '../views/Activities.vue'
import LogIn from '../views/LogIn.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/movies',
    name: 'Movies',
    component: Movies
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes
  },
  {
    path: '/games',
    name: 'Games',
    component: Games
  },
  {
    path: '/activities',
    name: 'Activities',
    component: Activities
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
